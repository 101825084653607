<template>
    <div class="brand">
        <jy-query ref="checkForm" :model="formInline" label-width="72px">
            <jy-query-item label="场站管理单位:" prop="name" label-width="120px">
                <el-input v-model="formInline.name" placeholder="请输入"></el-input>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck">查询</el-button>
                <el-button type="primary" @click="resetForm('checkForm')">重置</el-button>
                <!-- <el-button type="primary" @click="onexport">导出</el-button> -->
                <el-row>
                    <el-button type="primary" @click="add">新增</el-button>
                    <el-button type="primary" @click="batchdelete">批量删除</el-button>
                    <!-- <el-button type="primary" @click="batchimport">导入</el-button> -->
                </el-row>
            </template>
        </jy-query>
        <jy-table :data="lists" @select-all="selectallchange" @select="selectchange">
            <jy-table-column type="index" width="60" label="序号"> </jy-table-column>
            <jy-table-column type="selection" width="40"> </jy-table-column>
            <jy-table-column prop="name" label="场站管理单位" min-width="100"> </jy-table-column>
            <jy-table-column prop="createDate" label="创建日期" min-width="100"> </jy-table-column>
            <jy-table-column prop="remark" label="备注" min-width="200"> </jy-table-column>
            <jy-operate v-bind:list="operateList" width="200"></jy-operate>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total">
        </jy-pagination>
        <add-dialog ref="dialog" @updateList="getlist"></add-dialog>
    </div>
</template>
<script>
import adddialog from '@/components/pages/admin/basicInformation/basicData/depotManagement/adddepot'
export default {
    data() {
        return {
            formInline: {
                name: '',
            },
            lists: [
                {
                    name: '',
                    createDate: '',
                    remark: '',
                },
            ],
            // 批量删除
            batchdeleteList: [],
            // 分页
            pageIndex: 1,
            total: 100,
            pageSize: 10,
            //  操作
            operateList: [],
        }
    },
    components: {
        'add-dialog': adddialog,
    },
    created() {
        this.getlist()
        this.setOperateList()
    },
    methods: {
        setOperateList() {
            let l = [
                {
                    name: '修改',
                    icon: 'el-icon-edit-outline',
                    fun: this.handleEdit,
                },
                {
                    name: '删除',
                    icon: 'el-icon-delete',
                    fun: this.handleDelete,
                },
            ]
            this.operateList = l
        },
        getlist() {
            let url = '/basestationmunit/list'
            let option = {
                ...this.formInline,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }
            this.$http.post(url, option).then(({ detail }) => {
                this.lists = detail.list
                this.total = detail.total
            })
        },
        deleteRecord(brandIdAry) {
            this.$http
                .post('/basestationmunit/delete', brandIdAry)
                .then(res => {
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    })
                    this.getlist()
                })
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getlist()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.oncheck()
        },
        // 导出
        onexport() {
            console.log('导出')
        },
        // 新增
        add() {
            this.$refs.dialog.init()
            console.log('新增')
        },
        // 批量删除
        batchdelete() {
            if (!this.batchdeleteList.length) {
                this.$message({
                    type: 'warning',
                    message: '请先选择删除项',
                })
                return false
            }
            this.$confirm('是否删除选中场站管理单位?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let option = []
                    this.batchdeleteList.forEach(item => {
                        option.push(item.stationMUnitId)
                    })
                    this.deleteRecord(option)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
        // 批量导入
        batchimport() {},

        selectchange(selection) {
            console.log(selection)
            this.batchdeleteList = selection
        },
        selectallchange(selection) {
            console.log(selection)
            this.batchdeleteList = selection
        },

        // 删除
        handleDelete(row) {
            this.$confirm('是否删除该场站管理单位?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let params = []
                    params.push(row.stationMUnitId)
                    this.deleteRecord(params)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
            //   console.log(row)
        },
        // 修改
        handleEdit(row) {
            this.$refs.dialog.init(row, 'update')
            console.log('编辑')
        },
        // 查看
        // handlecheck(row) {
        //     this.$refs.dialog.init(row, 'check')
        //     console.log('查看')
        // },
        // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.pageSize = val
            this.getlist()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
            this.pageIndex = val
            this.getlist()
        },
    },
}
</script>
<style lang="scss" scoped="brand">
</style>
<style lang="scss">
</style>
